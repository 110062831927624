<template>
  <v-menu
    rounded="bx-l"
    offset-y
    transition="scroll-y-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        text
        active-class="primary"
        :small="true"
      >
        {{ ButtonName }}
        <v-icon right dark>
          mdi-chevron-down
        </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, i) in menuData"
        :key="i"
        :to="{name: item.option}"
        active-class="primary"
      >
        <v-list-item-title>{{ item.option }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    menuData: {
      type: Array,
      required: true
    },
    ButtonName: {
      type: String,
      required: true
    }
  },
}
</script>
